import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import LandingPage from '../components/page/LandingPage'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityLandingPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
      pageSidebar {
        title
        links {
          _key
          title
          siteLink
        }
      }
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const PagePage = ({data: {sanityLandingPage: page}}) => (
  <Layout mainImage={page.mainImage} title={page.title}>
    {page.seoSettings && page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <LandingPage
      title={page.title}
      _rawBody={page._rawBody}
      mainImage={page.mainImage}
      slug={page.slug.current}
      sideBar={page.pageSidebar}
    />
  </Layout>
)

export default PagePage
