import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'

import styles from './incentives.module.css'

export default function BusinessIncentives () {
  const data = useStaticQuery(graphql`
    {
      allSanityBusinessIncentive(sort: {order: ASC, fields: sortOrder}) {
        edges {
        node {
          id
          slug {
            current
          }
          _id
          title
          teaserText
        }
      }
    }
  }
  `)
  // console.log({data})
  return (
    <div >
      {data.allSanityBusinessIncentive.edges.map(biz => (
        <div key={biz.node.id} className={styles.incentiveWrapper}>
          <h3 className={styles.incentiveTitle}><Link to={`/business-incentives/${biz.node.slug.current}`} title={biz.node.title}>{biz.node.title}</Link></h3>
          <p>{biz.node.teaserText}</p>
          <p className={styles.incentiveLink}><Link to={`/business-incentives/${biz.node.slug.current}`} title={biz.node.title}>Incentive Details</Link></p>
        </div>
      ))}
    </div>
  )
}
