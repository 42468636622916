import React, {useState, useEffect} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {useCookies} from 'react-cookie'
import Map from '../../components/Map/Map'

import styles from '../Map/map.module.css'

export default function OutdoorArt () {
  const data = useStaticQuery(graphql`
    {
      allSanityArt(
        sort: {fields: [artCategories___title, artist], order: ASC},
        filter: {geopoint: {lat: {ne: null}}, artCategories: {_id: {ne: null}}}) {
        edges {
        node {
          id
          slug {
            current
          }
          _id
          title
          artist
          year
          _rawArtCategories
          artCategories {
            _id
            title
          }
          location
          digitalPuzzle
          geopoint {
            lat
            lng
          }
          images {
            _key
            alt
            asset {
              url
              fluid(maxWidth: 384) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    allSanityArtCategories(sort: {fields: title, order: ASC}) {
    edges {
      node {
        _id
        title
      }
    }
    }
  }
  `)

  const [artCategory, setArtCategory] = useState('')
  const [outdoorArt, setOutdoorArt] = useState([])
  const [cookies, setCookie, removeCookie] = useCookies(['OutdoorArtCategory'])
  const maxAge = 3600 // 30 (2592000) days

  const handleOutdoorArtChange = e => {
    setArtCategory(e.target.value)
    if (e.target.value) {
      setCookie('OutdoorArtCategory', e.target.value, {path: '/', maxAge})
    } else {
      // removeCookie(cookies['OutdoorArtCategory'])
      // removeCookie('OutdoorArtCategory', '', {path: '/', maxAge})
      removeCookie('OutdoorArtCategory', {path: '/'})
    }
    setArtCategory(e.target.value)
    // console.log(e.target.value)
    // console.log('handleOutdoorArtChange')
  }
  const handleSubmit = e => {
    e.preventDefault()
    // console.log('submit')
    const filteredData = data.allSanityArt.edges.filter(item => {
      let artCategoryResults = item

      if (artCategory && item.node._rawArtCategories && item.node._rawArtCategories._ref === artCategory) {
        // console.log({item})
        // console.log('yes')
        artCategoryResults = item
      } else if (artCategory === '') {
        artCategoryResults = item
      } else {
        artCategoryResults = null
      }
      return artCategoryResults
    })
    setOutdoorArt(filteredData)
    // console.log({artCategory})
    // console.log({cookies})
  }

  const autoSubmit = () => {
    // e.preventDefault()

    // let cat = cookies['OutdoorArtCategory']
    // console.log('autoSubmit')
    setArtCategory(cookies['OutdoorArtCategory'])
    // console.log({artCategory})
    // console.log(cookies['OutdoorArtCategory'])

    if (cookies['OutdoorArtCategory']) {
      // console.log(cookies['OutdoorArtCategory'])
      const filteredData = data.allSanityArt.edges.filter(item => {
        let artCategoryResults = item

        if (artCategory && item.node._rawArtCategories && item.node._rawArtCategories._ref === artCategory) {
        // console.log({item})
          // console.log('yes')
          artCategoryResults = item
        } else if (artCategory === '') {
          artCategoryResults = item
        } else {
          artCategoryResults = null
        }
        return artCategoryResults
      })
      setOutdoorArt(filteredData)
    } else {
      setArtCategory('')
      setOutdoorArt(data.allSanityArt.edges)
    }
  }

  useEffect(() => {
    // console.log('use effect')
    if (cookies['OutdoorArtCategory']) {
      // console.log('cookie')
      // console.log(cookies['OutdoorArtCategory'])
      setArtCategory(cookies['OutdoorArtCategory'])
      autoSubmit()
    } else {
      setArtCategory('')
      // console.log('no cookie')
      autoSubmit()
      // setOutdoorArt(data.allSanityArt.edges)
    }
  }, [artCategory])

  const handleReset = e => {
    // console.log('reset')
    e.preventDefault()
    removeCookie('OutdoorArtCategory', {path: '/'})
    // console.log(cookies['OutdoorArtCategory'])
    setArtCategory('')
    // document.getElementById('artCategories').selectedIndex = 0
    // setSearched(false)
    setOutdoorArt(data.allSanityArt.edges)
  }

  return (
    <div >
      {/* Art filter here */}

      <form onSubmit={handleSubmit} className={styles.catForm}>
        <div>
          <label className='sr-only' htmlFor='artCategories'>Outdoor Art Categories</label>
          <select id='artCategories' name='artCategories' className={styles.customSelect} onChange={handleOutdoorArtChange} value={artCategory}
          >
            <option value='' defaultValue>All Outdoor Art</option>
            {data.allSanityArtCategories.edges.map(cat => (
              <option value={cat.node._id} key={cat.node._id} >{cat.node.title}</option>
            ))}
          </select>

        </div>
        <div className={styles.buttonContainer} style={{display: 'flex'}}>
          <button
            className={styles.customButton}
            onClick={handleSubmit}
            name='submit'
          >Search
          </button>

          <button
            className={styles.customButtonReset}
            onClick={handleReset}
            name='reset'
            type='reset'
          >Reset
          </button>
        </div>

      </form>
      {outdoorArt && (<Map art={outdoorArt} />)}
    </div>
  )
}
