import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

// import Image from 'gatsby-image'
import moment from 'moment'
// import { DateRange } from 'react-date-range';
import { DateRangePicker } from 'react-date-range'; //defaultStaticRanges
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  startOfYear,
  endOfYear,
} from 'date-fns';
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file


import styles from './calendar.module.css'

import './OurCalendar.css' // theme css file


export default function OurCalendar() {
  const [events, setEvents] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [startDate, setStartDate] = useState(startOfDay(new Date()))
  const [endDate, setEndDate] = useState(endOfYear(new Date()))

  const data = useStaticQuery(graphql`
   {
  allSanityEvent(sort: {order: ASC, fields: startDate___local}) {
      edges {
        node {
          id
          title
          ongoingEvent
          ongoingEventNote
          slug {
            current
          }
          shortDescription
          startDate {
            local
          }
          endDate {
            local
            utc
          }
          teaserImage {
            asset {
              url
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanitySiteSettings {
      eventTeaserFallback {
        asset {
          url
          fluid(maxWidth: 250) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
  `)

  const EventDateFormat = ({ start, end }) => {
    const shortDayTime = `${moment(start).format('MMM Do YYYY, h:mma')} to ${moment(end).format('h:mma')}`
    const longDayTime = `${moment(start).format('MMM Do YYYY, h:mma')} to ${moment(end).format('MMM Do YYYY, h:mma')}`
    return moment(start).isSame((end), 'day') ? shortDayTime : longDayTime
  }

  const eventsFromData = data.allSanityEvent.edges

  const todayDate = new Date().toISOString().slice(0, 10)

  const ongoingEventsFromData = eventsFromData.filter(event => event.node.ongoingEvent === true).filter(event2 => event2.node.startDate.local <= todayDate).filter(event2 => event2.node.endDate.local >= todayDate)


  // Create an object to store the grouped events
  const groupedEvents = []

  // Map through the events and group them by startDate.local day
  eventsFromData.filter(event => event.node.ongoingEvent !== true).map((event) => {
    const startDate = new Date(event.node.startDate.local)
    const day = startDate.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' })

    if (!groupedEvents[day]) {
      groupedEvents[day] = []
    }

    groupedEvents[day].push(event.node)
  })

  const toDate = (dateStr) => new Date(dateStr)

  // Filter out day keys without events and sort the remaining events by next upcoming event
  const upcomingEventsByDay = Object.keys(groupedEvents)
    .filter((day) => groupedEvents[day].some((event) => toDate(event.startDate.local) > new Date()))
    .map((day) => {
      const upcomingEvents = groupedEvents[day].filter((event) => toDate(event.startDate.local) > new Date())
        .sort((a, b) => toDate(a.startDate.local) - toDate(b.startDate.local))

      return { day, events: upcomingEvents }
    })

  const renderCalendarIcon = (dateStr) => {
    const date = moment(dateStr, 'M/D/YYYY')

    return {
      'month': date.format('MMM'),
      'day': date.format('D'),

      'dayName': date.format('dddd')
    }
  }

  // create a useEffect to add the upcomingEventsByDay to state
  useEffect(() => {
    setEvents(upcomingEventsByDay)
    setAllEvents(upcomingEventsByDay)
  }, [])


  const handleSelect = (date) => {
    let filtered = allEvents.filter((event) => {
      let eventDate = new Date(event.day);
      return (eventDate >= date.selection.startDate &&
        eventDate <= date.selection.endDate);
    })
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setEvents(filtered);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }


  const defines = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfNextWeek: startOfWeek(addDays(new Date(), + 7)),
    endOfNextWeek: endOfWeek(addDays(new Date(), + 7)),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    // Next Month
    startOfNextMonth: startOfMonth(addMonths(new Date(), +1)),
    endOfNextMonth: endOfMonth(addMonths(new Date(), +1)),
    // This year
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
  };

  return (
    <div>

      <div className={styles.calAndEventsWrapper}>

        <div className={styles.eventsWrapper}>
          {
            events?.map((day) => {
              return (
                <div key={day.day} className={styles.dayGroupWrapper}>

                  <div className={styles.calendarIconWrapper}>
                    <div className={styles.calendarIcon}>
                      <span className={styles.month}>{renderCalendarIcon(day.day).month}</span>
                      <span className={styles.day}> {renderCalendarIcon(day.day).day}</span>
                      <span className={styles.dayName}>{renderCalendarIcon(day.day).dayName}</span>
                    </div>
                  </div>

                  <div key={day.day} className={styles.daysGroupWrapper}>
                    {
                      day?.events?.map((event) => {
                        return (
                          <div key={event.id} className={styles.eventWrapper}>

                            <div className={styles.eventTeaserImage} />
                            <div className={styles.eventDetails}>
                              <h3 className={styles.eventTitle}><Link to={`/our-calendar/${event.slug.current}`}>{event.title}</Link></h3>
                              <p className={styles.eventDate}>
                                <EventDateFormat start={event.startDate.local} end={event.endDate.local} />
                              </p>

                              <p className={styles.eventLink}><Link to={`/our-calendar/${event.slug.current}`}>View Event Details</Link></p>
                            </div>

                          </div>
                        )
                      }
                      )}
                  </div>
                </div>
              )
            })
          }
        </div>

      </div>
      {ongoingEventsFromData.length > 0 &&
        (<div className={styles.ongoingEventsWrapper}>
          <h2 className={styles.onGoingEventGroupTitle}>Ongoing Events</h2>
          {ongoingEventsFromData.map((event) => {
            return (
              <div key={event.node.id} className={styles.eventWrapper}>

                <h3 className={styles.eventTitle}><Link to={`/our-calendar/${event.node.slug.current}`}>{event.node.title}</Link></h3>
                <p className={styles.eventDate}>
                  {event?.node?.ongoingEventNote}
                </p>
                <p className={styles.eventLink}><Link to={`/our-calendar/${event.node.slug.current}`}>View Event Details</Link></p>
              </div>
            )
          })}

        </div>
        )
      }


    </div>
  )
}
