import React, {useEffect, useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import axios from 'axios'
import Business from './displays/Entertainment'

import styles from './directory.module.css'

export default function BusinessDirectory () {
  const data = useStaticQuery(graphql`
 {
  allSanityEntertainmentAndRecreationCategories {
    edges {
      node {
        title
        _id
        id
      }
    }
  }
}
`)

  // console.log({data})
  // Set State
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [businessDirectory, setBusinessDirectory] = useState({})

  useEffect(() => {
    fetchLocations()
  }, [])

  // Collect the Dynamic content
  const fetchLocations = () => {
    setLoading(true)
    axios
      .get(`/live.json`)
      .then(data => {
        // console.log({data})
        // Update date
        if (data) {
          setError(false)
        } else {
          setError(true)
        }
        setBusinessDirectory({
          data: data.data
        })
      })
      .catch(error => {
        // console.log({error})
        setError(true)
        setLoading(false)
      })
  }
  // console.log({businessDirectory})

  return (
    <>
      {error === true ? (
        <p>There has been an error, please try refreshing the page.</p>
      ) : loading && !businessDirectory.data ? (
        <div className={styles.loaderWrapper}>
          <span className={styles.loader} />
        </div>
      ) : (
        <>
          <Business
            data={businessDirectory.data}
            entertainmentAndRecreationCategories={data.allSanityEntertainmentAndRecreationCategories.edges}
          />
        </>
      )}
    </>
  )
}
