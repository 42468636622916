import React, {useState} from 'react'
import {Link} from 'gatsby'
import Image from 'gatsby-image'
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactMapGL, {Popup, FlyToInterpolator, NavigationControl, FullscreenControl, ScaleControl} from 'react-map-gl'
import LinkIcon from '../../lib/LinkIcon'
import styles from './map.module.css'

import Pins from './Pin'
import PopUpInfo from './PopUp'

const fullscreenControlStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
}

const navStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px'
}

const scaleControlStyle = {
  position: 'absolute',
  bottom: 36,
  left: 0,
  padding: '10px'
}

export default function Map ({art}) {
  const TOKEN = 'pk.eyJ1IjoidmFyaWFudHN0dWRpb3N0ZWFtIiwiYSI6ImNrYWVsa3N5cTA0YjMycWxkMWs5dW14aHUifQ.Lu3czqOSejBpm29byrOXhA'
  const [viewport, setViewport] = useState({
    latitude: 40.41921,
    longitude: -104.69072,
    zoom: 14.5,
    bearing: 0,
    pitch: 0
  })

  const [popupInfo, setPopupInfo] = useState(false)

  const onClickMarker = data => {
    setPopupInfo(data)
  }

  const renderPopup = () => {
    return (
      popupInfo && (
        <Popup
          tipSize={10}
          anchor='top'
          longitude={popupInfo.geopoint.lng}
          latitude={popupInfo.geopoint.lat}
          closeOnClick={false}
          onClose={() => setPopupInfo(null)}
        >
          <PopUpInfo data={popupInfo} full />
        </Popup>
      )
    )
  }

  const goToViewport = (art) => {
    onViewportChange({
      latitude: art.node.geopoint.lat,
      longitude: art.node.geopoint.lng,
      zoom: 17,
      transitionInterpolator: new FlyToInterpolator({speed: 3}),
      transitionDuration: 'auto'
    })
    setPopupInfo(art.node)
  }

  const onViewportChange = viewport => setViewport({...viewport, ...viewport})

  return (
    <>

      <div className={styles.mapWrapper}>

        <div className={styles.map}>
          <ReactMapGL
            {...viewport}
            width='100%'
            height='95vh'
            mapStyle='mapbox://styles/mapbox/light-v10'
            onViewportChange={setViewport}
            mapboxApiAccessToken={TOKEN}
            scrollZoom={false}
            movingMethod='flyTo'
          >
            <Pins data={art} onClick={onClickMarker} />
            <div style={fullscreenControlStyle}>
              <FullscreenControl />
            </div>
            <div style={navStyle}>
              <NavigationControl />
            </div>
            <div style={scaleControlStyle}>
              <ScaleControl />
            </div>
            {renderPopup()}
          </ReactMapGL>
        </div>

        <div className={styles.list}>
          {art.map(art => (
            <div key={art.node.id} className={styles.listItem}>
              {art.node.images && art.node.images[0] && art.node.images[0].asset && (<div className={styles.imageWrapper}><Link to={`/outdoor-art/${art.node.slug.current}`} title={art.node.title}>
                <Image fluid={art.node.images[0].asset.fluid} alt={art.node.title} /></Link>
              </div>)}
              <div className={styles.contentWrapper}>
                <h3 className={styles.title}><Link to={`/outdoor-art/${art.node.slug.current}`} title={art.node.title}>{art.node.title}</Link></h3>
                <p className={styles.artist}><span className={styles.by}>by</span> {art.node.artist}</p>
                <p className={styles.cat}>{art.node.artCategories.title}</p>
              </div>

              <div className={styles.linksOuter}>
                <p className={styles.viewOnMap}><LinkIcon type='map' /> <button onClick={() => goToViewport(art)}>View on Map</button></p>
                <p className={styles.viewArt}><Link to={`/outdoor-art/${art.node.slug.current}`} title={art.node.title}>View Art</Link></p>
              </div>

            </div>
          ))}
        </div>
      </div>

    </>
  )
}
