import React from 'react'
import Image from 'gatsby-image'
import {useStaticQuery, graphql, Link} from 'gatsby'

import styles from './properties.module.css'

export default function ResidentialProperties () {
  const data = useStaticQuery(graphql`
    {
      allSanityProperty(filter: {propertyType: {eq: "Residential"}}) {
        edges {
          node {
            _id
            title
            slug {
              current
            }
            propertyType
            saleOrLease
            _createdAt
            _rawBody
            _rawLocation
            _rawMainImage
            teaserImage {
              asset {
                url
                fluid(maxWidth: 250) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
      sanitySiteSettings {
        residentialPropertyTeaserFallback {
          asset {
            url
            fluid(maxWidth: 250) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)
  // console.log({data})
  return (
    <>
      {data.allSanityProperty.edges.map(property => (
        <div key={property.node._id} className={styles.propertyWrapper}>

          <div className={styles.propertyTeaserImage}>
            <Link to={`/residential-properties/${property.node.slug.current}`}>
              <Image fluid={property.node.teaserImage && property.node.teaserImage.asset ? property.node.teaserImage.asset.fluid : data.sanitySiteSettings.residentialPropertyTeaserFallback.asset.fluid} alt={property.node.title} /></Link>
          </div>

          <div className={styles.propertyDetails}>
            <h3 className={styles.propertyTitle}><Link to={`/residential-properties/${property.node.slug.current}`}>{property.node.title}</Link></h3>
            <p>{property.node._rawLocation.streetAddress}<br />
              {property.node._rawLocation.city}, {property.node._rawLocation.state} {property.node._rawLocation.zipcode}
            </p>
            <p className={styles.propertyLink}> <Link to={`/residential-properties/${property.node.slug.current}/`} title={property.node.title}>Property Details</Link> </p>
          </div>

        </div>
      ))}
    </>
  )
}
