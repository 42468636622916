import React, {useState, useEffect, useRef} from 'react'
import {useCookies} from 'react-cookie'
import {Link} from 'gatsby'
import LinkIcon from '../../../lib/LinkIcon'
import styles from './business.module.css'

// nightlifeCategories
export default function Business ({data, diningCategories, entertainmentAndRecreationCategories, retailCategories, serviceCategories, lodgingAndEventCentersCategories}) {
  // console.log({data})

  // Set State
  const [searchTitle, setSearchTitle] = useState('')
  const [businessData, setBusinessData] = useState(data)
  const [diningCategory, setDiningCategory] = useState('')
  const [searched, setSearched] = useState(false)

  // TODO: COOKIE
  const [cookies, setCookie, removeCookie] = useCookies(['DiningDirectory', 'DiningSearch', 'DiningDate'])
  const maxAge = 3600 // 30 (2592000) days

  const messagesEndRef = useRef(null)

  //  TODO: COOKIE
  const handleDiningCategoriesChange = e => {
    setDiningCategory(e.target.value)
    if (e.target.value) {
      setCookie('DiningDirectory', e.target.value, {path: '/', maxAge})
      setCookie('DiningDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('DiningDirectory', {path: '/'})
    }
  }

  // TODO: Cookie
  const handleTitleChange = e => {
    setSearchTitle(e.target.value)
    if (e.target.value) {
      setCookie('DiningSearch', e.target.value, {path: '/', maxAge})
      setCookie('DiningDate', Date.now(), {path: '/', maxAge})
    } else {
      removeCookie('DiningSearch', {path: '/'})
    }
  }

  const handleReset = e => {
    e.preventDefault()
    // TODO: COOKIE
    removeCookie('DiningDirectory', {path: '/'})
    removeCookie('DiningSearch', {path: '/'})
    removeCookie('DiningDate', {path: '/'})

    setDiningCategory('')
    setSearchTitle('')
    document.getElementById('diningCategories').selectedIndex = 0
    document.getElementById('title').value = ''

    setSearched(false)
    setBusinessData(data)
  }

  // Form Functions
  const handleSubmit = e => {
    e.preventDefault()

    const filteredData = data.filter(item => {
      let diningCategoryResults = item
      let titleResults = item
      // console.log({item})

      if (diningCategory) {
        diningCategoryResults =
        item.node._rawBusinessCategories.diningCategories && item.node._rawBusinessCategories.diningCategories.filter(
          ({_ref}) => _ref === diningCategory
        ).length > 0
      }

      if (searchTitle) {
        titleResults =
          item.node.title
            .toLowerCase()
            .search(searchTitle.toLowerCase()) !== -1
      }
      setSearched(true)
      return titleResults && diningCategoryResults
    })
    setBusinessData(filteredData)
    messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
  }

  // TODO: Cookie
  const autoSubmit = () => {
    console.log('autoSubmit')
    setDiningCategory(cookies['DiningDirectory'])

    if (cookies['DiningDirectory'] || cookies['DiningSearch']) {
      // console.log(cookies['DiningDirectory'])
      const filteredData = data.filter(item => {
        let diningCategoryResults = item
        let titleResults = item
        // console.log({item})

        if (diningCategory) {
          diningCategoryResults =
          item.node._rawBusinessCategories.diningCategories && item.node._rawBusinessCategories.diningCategories.filter(
            ({_ref}) => _ref === diningCategory
          ).length > 0
        }

        if (searchTitle) {
          titleResults =
            item.node.title
              .toLowerCase()
              .search(searchTitle.toLowerCase()) !== -1
        }
        setSearched(true)
        return titleResults && diningCategoryResults
      })
      setBusinessData(filteredData)
      messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
    } else {
      setDiningCategory('')
      setBusinessData(data)
    }
  }

  // TODO: Cookie
  useEffect(() => {
    if (cookies['DiningDirectory'] && cookies['DiningSearch']) {
      setDiningCategory(cookies['DiningDirectory'])
      setSearchTitle(cookies['DiningSearch'])
      autoSubmit()
    } else if (cookies['DiningDirectory']) {
      setDiningCategory(cookies['DiningDirectory'])
      autoSubmit()
    } else if (cookies['DiningSearch']) {
      setSearchTitle(cookies['DiningSearch'])
      autoSubmit()
    } else {
      setDiningCategory('')
      setSearchTitle('')
      autoSubmit()
    }
  }, [diningCategory, searchTitle])

  // console.log({businessData})
  // console.log({diningCategory})
  return (
    <div>
      {/* Business Directory - FILTERS value={diningCategory || ''} */}
      <div className={styles.filterWrapper}>
        <form onSubmit={handleSubmit} className={styles.directoryForm3}>

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='diningCategories'
            >Eat & Drink Categories</label>
            <select id='diningCategories' name='diningCategories' className={styles.customSelect} onChange={handleDiningCategoriesChange} value={diningCategory}
            >
              {/* <option defaultValue disabled value=''>Dining Categories</option> */}
              <option value='' defaultValue className={styles.customSelectDisabled}>Eat & Drink Categories</option>
              {diningCategories.map(cat => (
                <option value={cat.node._id} key={cat.node.id}>{cat.node.title}</option>
              ))}
            </select>
          </div>

          <div className={styles.fieldContainer}>
            <label
              className='sr-only'
              htmlFor='title'
            >By Title</label>
            <input
              onChange={handleTitleChange}
              id='title'
              name='title'
              type='text'
              placeholder='Search Businesses'
              className={styles.customInput}
              value={searchTitle}
            />
          </div>

          <div className={styles.buttonContainer} style={{display: 'flex'}}>
            <button
              className={styles.customButton}
              onClick={handleSubmit}
              name='submit'
            >Search
            </button>
            <button
              className={styles.customButtonReset}
              onClick={handleReset}
              name='reset'
              type='reset'
            >Reset
            </button>

          </div>

        </form>
      </div>
      {/* Business Directory - VIEW  */}
      <div aria-live='polite' ref={messagesEndRef} className={businessData.length > 0 && searched === true ? (styles.directoryTeaserWrapperSideBar) : undefined}>
        {businessData.length <= 0 && searched === true ? (<div className={styles.noResults}>There are no results for this search. Please click the RESET button for a complete list of results.</div>) : (<></>)}
        {searched ? (
          businessData.map((biz, i) => (
            <address key={i}>
              <div className='vcard'>

                <div className='org'><h3 className={styles.directoryBizTitle}> <Link to={`/business-directory/${biz.node.slug.current}/`}>{biz.node.title}</Link></h3></div>

                <div className='adr'>
                  <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(biz.node.location.streetAddress)},${encodeURI(biz.node.location.city)},${encodeURI(biz.node.location.state)}`} target='_blank' rel='noopener noreferrer' title={`Directions to ${biz.node.title}`} className={styles.directoryAddress}>
                    <div className='street-address'>{biz.node.location.streetAddress}</div>
                    <span className='locality sr-only'>{biz.node.location.city}</span> <span className='region sr-only'>{biz.node.location.state}</span>{' '}
                    <span className='postal-code sr-only'>{biz.node.location.zip}</span>
                    <span className='country-name sr-only'>United States</span>
                  </a>
                </div>
                <div className={styles.mapDirections}><LinkIcon type='map' />
                  <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(biz.node.location.streetAddress)},${encodeURI(biz.node.location.city)},${encodeURI(biz.node.location.state)}`} target='_blank' rel='noopener noreferrer' title={`Directions to ${biz.node.title}`} className={styles.mapLink}>map &amp; directions</a>
                </div>

                {biz.node.businessContact && biz.node.businessContact && (
                  <div className='tel' title={`Call ${biz.node.title}`}>
                    <LinkIcon type='call' />
                    <a href={`tel:${biz.node.businessContact.contactPhone}`} className={styles.callLink}>{biz.node.businessContact.contactPhone}</a>
                  </div>
                )}

                {/* <div><Link to={`/${biz.node.slug.current}/`}>More Info</Link></div> */}

              </div>
            </address>
          ))
        ) : (
          <>
            {/* <div className={styles.searchedNo}>Use the filters and/or search above to find the business(es) you’re look looking for.</div> */}
          </>
        )}
      </div>
    </div>
  )
}
