import React from 'react'
// import Image from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
import PortableText from '../serializers/portableText'
import Container from '../container'
import SideBar from '../SideBar'
import styles from './page.module.css'
import BusinessDirectory from '../views/BusinessDirectory'
import DiningDirectory from '../views/DiningDirectory'
import RetailDirectory from '../views/RetailDirectory'
import OurCalendar from '../views/OurCalendar'
import SignatureEvents from '../views/SignatureEvents'
import CommercialProperties from '../views/CommercialProperties'
import BusinessIncentives from '../views/BusinessIncentives'
import LiveDirectory from '../views/ResidentialProperties'
import EntertainmentDirectory from '../views/EntertainmentDirectory'
import OutdoorArt from '../views/OutdoorArt'
// import DiscoverArt from '../views/DiscoverArt'
import PhotoGallery from '../views/PhotoGallery'
import Footer from '../Footer'
import Img from 'gatsby-image'
import MostUpcomingEvent from '../views/MostUpcomingEvent'

const Page = ({title, _rawBody, sideBar, slug}) => {
  const data = useStaticQuery(graphql`
  {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
    }
    sanitySiteSettings {
      logos {
        _key
        link
        title
        logoImage {
          asset {
            url
          }
        }
      }
    }
  }
`)
  // console.log({sideBar})
  return (
    <>

      <article className={styles.root} >

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div>
            <div className={styles.mainContent}>

              <div className={`${styles.innerMainContent} ${slug === 'our-calendar' && 'ourCalendarLandingPageWrapper'}`}>
                {slug === 'our-calendar' && (<MostUpcomingEvent />)}

                {_rawBody && <PortableText blocks={_rawBody} />}

                {/* business-directory */}
                {slug === 'business-directory' && (<BusinessDirectory />)}
                {/* dining-directory */}
                {slug === 'dining-directory' && (<DiningDirectory />)}
                {/* retail-directory */}
                {slug === 'retail-directory' && (<RetailDirectory />)}
                {/* entertainment-directory */}
                {slug === 'entertainment-directory' && (<EntertainmentDirectory />)}
                {/* our-calendar */}

                {slug === 'our-calendar' && (<OurCalendar />)}
                {/* special-events */}
                {slug === 'special-events' && (<SignatureEvents />)}
                {/* commercial-properties */}
                {slug === 'commercial-properties' && (<CommercialProperties />)}
                {/* business-incentives */}
                {slug === 'business-incentives' && (<BusinessIncentives />)}
                {/* housing options / live-directory / residential properties */}
                {slug === 'residential-properties' && (<LiveDirectory />)}
                {/* outdoor-art */}
                {slug === 'outdoor-art' && (<OutdoorArt />)}
                {/* photo-gallery */}
                {slug === 'photo-gallery' && (<PhotoGallery />)}

              </div>

              {sideBar && slug !== 'outdoor-art' && (<SideBar data={sideBar} barClass />)}

            </div>
          </div>
        </Container>
      </article>

      <Footer topBorder />
    </>
  )
}
export default Page
