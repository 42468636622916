import React, {useState, useEffect, useRef} from 'react'
import {useCookies} from 'react-cookie'
import {Link} from 'gatsby'
import LinkIcon from '../../../lib/LinkIcon'

import styles from './business.module.css'

// nightlifeCategories,
export default function Business ({data, diningCategories, entertainmentAndRecreationCategories, retailCategories, serviceCategories, lodgingAndEventCentersCategories}) {
  // console.log({data})

  // State hooks
  const [searchTitle, setSearchTitle] = useState('');
  const [businessData, setBusinessData] = useState(data);
  const [diningCategory, setDiningCategory] = useState('');
  const [entertainmentAndRecreationCategory, setEntertainmentAndRecreationCategory] = useState('');
  const [retailCategory, setRetailCategory] = useState('');
  const [serviceCategory, setServiceCategory] = useState('');
  const [lodgingAndEventCentersCategory, setLodgingAndEventCentersCategory] = useState('');
  const [searched, setSearched] = useState(false);

  // Cookie management
  const [cookies, setCookie, removeCookie] = useCookies([
    'DiningDirectoryBiz',
    'RetailDirectoryBiz',
    'EntAndRecDirectoryBiz',
    'ServiceDirectoryBiz',
    'CentersDirectoryBiz',
    'BizSearch',
    'BizDate',
  ]);
  const maxAge = 3600; // 1 hour

  const messagesEndRef = useRef(null);

  // Category change handlers
  const handleCategoryChange = (category, setCategory, cookieName) => (e) => {
    setCategory(e.target.value);
    if (e.target.value) {
      setCookie(cookieName, e.target.value, { path: '/', maxAge });
      setCookie('BizDate', Date.now(), { path: '/', maxAge });
    } else {
      removeCookie(cookieName, { path: '/' });
    }
  };

  // Title search handler
  const handleTitleChange = (e) => {
    setSearchTitle(e.target.value);
    if (e.target.value) {
      setCookie('BizSearch', e.target.value, { path: '/', maxAge });
      setCookie('BizDate', Date.now(), { path: '/', maxAge });
    } else {
      removeCookie('BizSearch', { path: '/' });
    }
  };

  // Reset filters
  const handleReset = (e) => {
    e.preventDefault();
    // Clear cookies
    removeCookie('DiningDirectoryBiz', { path: '/' });
    removeCookie('RetailDirectoryBiz', { path: '/' });
    removeCookie('EntAndRecDirectoryBiz', { path: '/' });
    removeCookie('ServiceDirectoryBiz', { path: '/' });
    removeCookie('CentersDirectoryBiz', { path: '/' });
    removeCookie('BizSearch', { path: '/' });
    removeCookie('BizDate', { path: '/' });

    // Reset state
    setDiningCategory('');
    setEntertainmentAndRecreationCategory('');
    setRetailCategory('');
    setServiceCategory('');
    setLodgingAndEventCentersCategory('');
    setSearchTitle('');
    setSearched(false);
    setBusinessData(data);
  };


  // Helper function for filtering items based on selected categories
  const filterItems = (item, categories) => {
    const businessCategories = item.node._rawBusinessCategories || {};

    const isDiningCategoryMatch = categories.diningCategory
      ? businessCategories.diningCategories?.some(({ _ref }) => _ref === categories.diningCategory)
      : true;

    const isEntertainmentCategoryMatch = categories.entertainmentAndRecreationCategory
      ? businessCategories.entertainmentAndRecreationCategories?.some(({ _ref }) => _ref === categories.entertainmentAndRecreationCategory)
      : true;

    const isRetailCategoryMatch = categories.retailCategory
      ? businessCategories.retailCategories?.some(({ _ref }) => _ref === categories.retailCategory)
      : true;

    const isServiceCategoryMatch = categories.serviceCategory
      ? businessCategories.serviceCategories?.some(({ _ref }) => _ref === categories.serviceCategory)
      : true;

    const isLodgingCategoryMatch = categories.lodgingAndEventCentersCategory
      ? businessCategories.lodgingAndEventCentersCategories?.some(({ _ref }) => _ref === categories.lodgingAndEventCentersCategory)
      : true;

    const isTitleMatch = categories.searchTitle
      ? item.node.title.toLowerCase().includes(categories.searchTitle.toLowerCase())
      : true;

    return (
      isTitleMatch &&
      isDiningCategoryMatch &&
      isEntertainmentCategoryMatch &&
      isRetailCategoryMatch &&
      isServiceCategoryMatch &&
      isLodgingCategoryMatch
    );
  };


  // Submit handler for search and filters
  const handleSubmit = (e) => {
    e.preventDefault();
    const categories = {
      diningCategory,
      entertainmentAndRecreationCategory,
      retailCategory,
      serviceCategory,
      lodgingAndEventCentersCategory,
      searchTitle,
    };

    const filteredData = data.filter((item) => filterItems(item, categories));

    setSearched(true);
    setBusinessData(filteredData);
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Automatic form submission on component mount if cookies exist
  const autoSubmit = () => {
    const categories = {
      diningCategory,
      entertainmentAndRecreationCategory,
      retailCategory,
      serviceCategory,
      lodgingAndEventCentersCategory,
      searchTitle,
    };

    const filteredData = data.filter((item) => filterItems(item, categories));
    setBusinessData(filteredData);
  };


  // UseEffect to auto submit on cookie presence
  useEffect(() => {
    // Read cookies and set them as initial state
    setDiningCategory(cookies['DiningDirectoryBiz'] || '');
    setEntertainmentAndRecreationCategory(cookies['EntAndRecDirectoryBiz'] || '');
    setRetailCategory(cookies['RetailDirectoryBiz'] || '');
    setServiceCategory(cookies['ServiceDirectoryBiz'] || '');
    setLodgingAndEventCentersCategory(cookies['CentersDirectoryBiz'] || '');
    setSearchTitle(cookies['BizSearch'] || '');

    // Flag to track first render
    const isInitialMount = !searched;

    // Trigger auto-submit to apply filters if cookies exist
    if (
      cookies['RetailDirectoryBiz'] ||
      cookies['DiningDirectoryBiz'] ||
      cookies['EntAndRecDirectoryBiz'] ||
      cookies['ServiceDirectoryBiz'] ||
      cookies['CentersDirectoryBiz'] ||
      cookies['BizSearch']
    ) {
      autoSubmit();
      setSearched(true); // Prevent re-triggering autoSubmit on further renders
    } else if (isInitialMount) {
      setBusinessData(data); // Ensure no filter is applied when there's no cookie
    }
  }, [cookies, data, searched]);



  return (
    <div>
      {/* Business Directory - FILTERS value={diningCategory || ''} */}
      <div className={styles.filterWrapper}>
      <form onSubmit={handleSubmit} className={styles.directoryForm4}>

      <div className={styles.fieldContainer}>
        <label className="sr-only" htmlFor="diningCategories">
          Dining Categories
        </label>
        <select
          id="diningCategories"
          name="diningCategories"
          className={styles.customSelect}
          onChange={handleCategoryChange('diningCategory', setDiningCategory, 'DiningDirectoryBiz')}
          value={diningCategory}
        >
          <option value="" defaultValue className={styles.customSelectDisabled}>
            Eat & Drink Categories
          </option>
          {diningCategories.map((cat) => (
            <option value={cat.node._id} key={cat.node.id}>
              {cat.node.title}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.fieldContainer}>
        <label
          className="sr-only"
          htmlFor="entertainmentAndRecreationCategories"
        >
          Entertainment And Recreation Categories
        </label>
        <select
          id="entertainmentAndRecreationCategories"
          name="entertainmentAndRecreationCategories"
          className={styles.customSelect}
          onChange={handleCategoryChange('entertainmentAndRecreationCategory', setEntertainmentAndRecreationCategory, 'EntAndRecDirectoryBiz')}
          value={entertainmentAndRecreationCategory}
        >
          <option value="" defaultValue>
            Entertainment &amp; Recreation Categories
          </option>
          {entertainmentAndRecreationCategories.map((cat) => (
            <option value={cat.node._id} key={cat.node.id}>
              {cat.node.title}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.fieldContainer}>
        <label className="sr-only" htmlFor="retailCategories">
          Retail Categories
        </label>
        <select
          id="retailCategories"
          name="retailCategories"
          className={styles.customSelect}
          onChange={handleCategoryChange('retailCategory', setRetailCategory, 'RetailDirectoryBiz')}
          value={retailCategory}
        >
          <option value="" defaultValue>
            Retail Categories
          </option>
          {retailCategories.map((cat) => (
            <option value={cat.node._id} key={cat.node.id}>
              {cat.node.title}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.fieldContainer}>
        <label className="sr-only" htmlFor="serviceCategories">
          Service Categories
        </label>
        <select
          id="serviceCategories"
          name="serviceCategories"
          className={styles.customSelect}
          onChange={handleCategoryChange('serviceCategory', setServiceCategory, 'ServiceDirectoryBiz')}
          value={serviceCategory}
        >
          <option value="" defaultValue>
            Service Categories
          </option>
          {serviceCategories.map((cat) => (
            <option value={cat.node._id} key={cat.node.id}>
              {cat.node.title}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.fieldContainer}>
        <label className="sr-only" htmlFor="lodgingAndEventCentersCategories">
          Lodging &amp; Event Centers Categories
        </label>
        <select
          id="lodgingAndEventCentersCategories"
          name="lodgingAndEventCentersCategories"
          className={styles.customSelect}
          onChange={handleCategoryChange('lodgingAndEventCentersCategory', setLodgingAndEventCentersCategory, 'CentersDirectoryBiz')}
          value={lodgingAndEventCentersCategory}
        >
          <option value="" defaultValue>
            Lodging &amp; Event Centers Categories
          </option>
          {lodgingAndEventCentersCategories.map((cat) => (
            <option value={cat.node._id} key={cat.node.id}>
              {cat.node.title}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.fieldContainer}>
        <label className="sr-only" htmlFor="title">
          By Title
        </label>
        <input
          onChange={handleTitleChange}
          id="title"
          name="title"
          type="text"
          placeholder="Search Businesses"
          className={styles.customInput}
          value={searchTitle}
        />
      </div>

      <div className={styles.buttonContainer} style={{ display: 'flex' }}>
        <button className={styles.customButton} type="submit">
          Search
        </button>
        <button
          className={styles.customButtonReset}
          onClick={handleReset}
          type="button"
        >
          Reset
        </button>
      </div>

    </form>

      </div>
      {/* Business Directory - VIEW  */}
      <div aria-live='polite' ref={messagesEndRef} className={businessData.length > 0 && searched === true ? (styles.directoryTeaserWrapperSideBar) : undefined}>
        {businessData.length <= 0 && searched === true ? (<div className={styles.noResults}>There are no results for this search. Please click the RESET button for a complete list of results.</div>) : (<></>)}
        {searched ? (
          businessData.map((biz, i) => (
            <address key={i}>
              <div className='vcard'>

                <div className='org'><h3 className={styles.directoryBizTitle}> <Link to={`/business-directory/${biz.node.slug.current}/`}>{biz.node.title}</Link></h3></div>

                <div className='adr'>
                  <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(biz.node.location.streetAddress)},${encodeURI(biz.node.location.city)},${encodeURI(biz.node.location.state)}`} target='_blank' rel='noopener noreferrer' title={`Directions to ${biz.node.title}`} className={styles.directoryAddress}>
                    <div className='street-address'>{biz.node.location.streetAddress}</div>
                    <span className='locality sr-only'>{biz.node.location.city}</span> <span className='region sr-only'>{biz.node.location.state}</span>{' '}
                    <span className='postal-code sr-only'>{biz.node.location.zip}</span>
                    <span className='country-name sr-only'>United States</span>
                  </a>
                </div>

                <div className={styles.mapDirections}><LinkIcon type='map' />
                  <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(biz.node.location.streetAddress)},${encodeURI(biz.node.location.city)},${encodeURI(biz.node.location.state)}`} target='_blank' rel='noopener noreferrer' title={`Directions to ${biz.node.title}`} className={styles.mapLink}>map &amp; directions</a>
                </div>

                {biz.node.businessContact && biz.node.businessContact.contactPhone && (
                  <div className='tel' title={`Call ${biz.node.title}`}>
                    <LinkIcon type='call' />
                    <a href={`tel:${biz.node.businessContact.contactPhone}`} className={styles.callLink}>{biz.node.businessContact.contactPhone}</a>
                  </div>
                )}

                {/* <div><Link to={`/${biz.node.slug.current}/`}>More Info</Link></div> */}

              </div>
            </address>

          ))
        ) : (
          <>
            {/* <div className={styles.searchedNo}>Use the filters and/or search above to find the business(es) you’re look looking for.</div> */}
          </>
        )}
      </div>
    </div>
  )
}
