import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import Image from 'gatsby-image'

import styles from './photogallery.module.css'

export default function PhotoGallery () {
  const data = useStaticQuery(graphql`
  {
    allSanityGallery(sort: {order: ASC, fields: sortOrder}) {
    edges {
      node {
        id
        title
        sortOrder
        slug {
          current
        }
        images {
          alt
          asset {
            fluid(maxWidth: 900) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
  }
  `)
  // console.log({data})
  return (
    <>
      {data.allSanityGallery.edges.map(gallery => (
        <div key={gallery.node.id} className={styles.galleryWrapper}>
          {/* teaser */}
          {gallery.node.images[0] && gallery.node.images[0].asset && (
            <div className={styles.galleryTeaserImage}>
              <Link to={`/photo-gallery/${gallery.node.slug.current}`} >
                <Image fluid={gallery.node.images[0].asset.fluid} alt={gallery.node.images[0].alt} /></Link>
            </div>
          )}
          <div className={styles.galleryDetails}>
            <h3 className={styles.galleryTitle}><Link to={`/photo-gallery/${gallery.node.slug.current}`} >{gallery.node.title}</Link></h3>
            <p className={styles.teaserText}>{gallery.node.teaserText}</p>
            <p className={styles.galleryLink}><Link to={`/photo-gallery/${gallery.node.slug.current}`} title={gallery.node.title}>View Gallery</Link></p>
          </div>

        </div>
      ))}
    </>
  )
}
