import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import Image from 'gatsby-image'

import styles from './signature.module.css'

export default function SignatureEvents () {
  const data = useStaticQuery(graphql`
  {
    allSanitySpecialEvent(sort: {fields: sortOrder, order: ASC}) {
      edges {
        node {
          slug {
            current
          }
          _id
          title
          teaserImage {
            asset {
              url
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid
              }
            }
          }
          teaserText
        }
      }
    }
  }
  `)
  // console.log({data})
  return (
    <>
      {data.allSanitySpecialEvent.edges.map(event => (
        <div key={event.node._id} className={styles.eventWrapper}>
          {event.node.teaserImage && event.node.teaserImage.asset && (
            <div className={styles.eventTeaserImage}>
              <Link to={`/special-events/${event.node.slug.current}`} >
                <Image fluid={event.node.teaserImage.asset.fluid} alt={event.node.title} /></Link>
            </div>
          )}
          <div className={styles.eventDetails}>
            <h3 className={styles.eventTitle}><Link to={`/special-events/${event.node.slug.current}`} >{event.node.title}</Link></h3>
            <p className={styles.teaserText}>{event.node.teaserText}</p>
            <p className={styles.eventLink}><Link to={`/special-events/${event.node.slug.current}`} title={event.node.title}>details</Link></p>
          </div>

        </div>
      ))}
    </>
  )
}
